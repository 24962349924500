import React from "react"
import Layout from "../components/layout"

import { Helmet } from "react-helmet"
import { Container, Row, Col } from "react-bootstrap"

import egResume from "../assets/pdfs/elena-gonzalez-resume.pdf"

const IndexPage = () => {
  return (
    <>
      <Helmet bodyAttributes={{ class: "home short" }} />
      <Layout>
        <Container className="home-contain">
          <Row>
            <Col>
              <h1 className="name">Elena G. González, PhD</h1>
              <h3 className="title">Genetics, Molecular Biology, Oncology</h3>
              <div className="home-button">
                <a className="btn btn-primary" role="button" href={egResume}>
                  Resume
                </a>
                <a
                  className="btn btn-primary"
                  href="/publications/all"
                  role="button"
                >
                  Publications
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default IndexPage
